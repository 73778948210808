@import "../variables";

.page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background: #ddd;
}

.login {
  position: relative;
  z-index: 1;
  width: 410px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;

  &_logo {
    display: block;
    width: 68px;
    height: 73px;
    margin-right: 23px;
  }

  &_title {
    font-size: 34px;
    font-weight: 800;
    line-height: 46px;
  }
}

.form {
  padding: 55px 30px 30px;
  margin-top: 20px;
  background: $color-white;
  border-radius: 15px;
  box-shadow: $shadow-3;

  &_title {
    margin: 0 0 30px;
    font-size: 26px;
    font-weight: 700;
    line-height: 29px;
    text-align: center;
  }

  &_submit {
    display: block;
    width: 100%;
    height: 58px;
    padding: 13px 18px 14px;
    font-size: 16px;
    font-weight: 600;
    line-height: 29px;
    border: none;
    border-radius: 15px;
  }

  &_infos {
    margin: 16px 0 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: $color-text-4;
    text-align: center;
  }

  :global {
    .ant-input-affix-wrapper {
      padding-top: 18px;
      padding-bottom: 19px;
      border-radius: 15px;
    }

    .ant-input {
      font-size: 16px;
      font-weight: 500;
      line-height: 21px;
      color: $color-text-1;
    }

    label {
      font-size: 16px;
      font-weight: 500;
      color: $color-text-3;
    }

    .ant-checkbox-inner {
      width: 26px;
      height: 26px;
      border-radius: 8px;
    }
  }
}

.forgetPwdLinkCtn {
  text-align: right;
}

.forgetPwdLink {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 500;
  color: $color-text-4;
  text-decoration: underline;
}

.bg {
  position: fixed;
  inset: 0;
  z-index: 0;
  background: #c4c4c4;

  &_img {
    width: 100%;
    height: 100%;
    opacity: 0.3;
    object-fit: cover;
  }
}
